
import { Component, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ProgressBarModule } from 'primeng/progressbar';
import { DisableKeyboardDirective } from 'src/app/core/directives/disable-keyboard.directive';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    AngularSvgIconModule,
    DisableKeyboardDirective,
    ProgressBarModule
  ],
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  isLoadingSig: Signal<boolean | undefined>;

  private readonly _loaderService = inject(LoaderService);

  constructor() {
    this.isLoadingSig = toSignal(this._loaderService.isLoading$);
  }
}
