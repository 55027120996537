import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Guid } from "guid-typescript";
import { BehaviorSubject, Observable, Subject, delay, merge } from "rxjs";
import { HttpRequestModel } from "../models/http-request.model";

@Injectable({
  providedIn: "root",
})
export class LoaderService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private _requests: HttpRequestModel[] = [];
  private _activeRequestSubject$: Subject<HttpRequestModel> =
    new Subject<HttpRequestModel>();
  private _passiveRequestSubject$: Subject<HttpRequestModel> =
    new Subject<HttpRequestModel>();
  private _activeRequest$: Observable<HttpRequestModel> =
    this._activeRequestSubject$.asObservable();
  private _passiveRequest$: Observable<HttpRequestModel> =
    this._passiveRequestSubject$.asObservable();
  private _minumumRequestTime = 100;
  private _waitedRequestTime = 1600;

  constructor() {
    merge(
      this._activeRequest$.pipe(delay(this._minumumRequestTime)),
      this._passiveRequest$.pipe(delay(this._waitedRequestTime))
    ).subscribe(_ => this._checkRequests());
  }

  private _checkRequests(): void {
    const isShow: boolean = this._requests.filter(x => x.isActive).length > 0;
    if (isShow) this.show();
    else this.hide();
    this._requests = this._requests.filter(
      x => new Date().getTime() - x.startTime.getTime() < 60000
    );
  }

  show(): void {
    this.isLoading$.next(true);
  }

  hide(): void {
    this.isLoading$.next(false);
  }

  private _getRequestId(request: HttpRequest<unknown>): Guid {
    return request.headers.has("requestId")
      ? Guid.parse(request.headers.get("requestId") ?? Guid.EMPTY)
      : Guid.parse(Guid.EMPTY);
  }

  private _getRequestModel(
    request: HttpRequest<unknown>
  ): HttpRequestModel | undefined {
    return this._requests.find(x => x.id.equals(this._getRequestId(request)));
  }

  addRequest(request: HttpRequest<unknown>): HttpRequestModel {
    const requestModel: HttpRequestModel = {
      id: this._getRequestId(request),
      startTime: new Date(),
      endTime: undefined,
      isActive: true,
    };
    this._requests.push(requestModel);
    this._activeRequestSubject$.next(requestModel);
    return requestModel;
  }

  completeRequest(request: HttpRequest<unknown>): void {
    const requestModel = this._getRequestModel(request);
    if (!requestModel) return;
    requestModel.isActive = false;
    requestModel.endTime = new Date();
    this._passiveRequestSubject$.next(requestModel);
  }
}
