import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { CaptchaComponent } from "./component/captcha/captcha.component";
import { environment } from "src/environments/environments";

@NgModule({
    imports: [CommonModule, RecaptchaV3Module, CaptchaComponent],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.captchaKey,
        },
    ],
})
export class CaptchaModule {}
