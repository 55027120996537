import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector, inject } from "@angular/core";
import { TranslocoService } from "@jsverse/transloco";
import { asapScheduler } from "rxjs";
import { NotificationService } from "src/app/shared/services/notification.service";
import { ToastNotificationService } from "../../shared/services/toast-notification.service";
import { ToastNotificationType } from "src/app/shared/enums/toast-notification-type.enum";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";

@Injectable({
  providedIn: "root",
})
export class ErrorHandlerService {
  private readonly _injector = inject(Injector);
  private readonly _notificationService = inject(NotificationService);
  private readonly _toastNotificationService = inject(ToastNotificationService);
  errorsList: string[] = [];
  translocoService!: TranslocoService;
  constructor() {
    asapScheduler.schedule(() => {
      this.init();
    });
  }
  handleError(error: HttpErrorResponse) {
    const errorType = error.error["ErrorType"];
    const errorCheck = this.errorsList.find(errorItem =>
      errorItem.includes(errorType)
    );
    if (!errorCheck) {
      console.error(error);
      return;
    }
    const errorParts = errorCheck.split(".");
    errorParts.pop();
    const errorKey = errorParts.join(".");

    this._toastNotificationService.showToast(errorKey as NotificationType, {
      severity: ToastNotificationType.error,
      life: 9000,
    });
    // this._notificationService.openNotificationForErrorHandling(
    //   errorKey,
    //   null,
    //   "oops.png"
    // );
  }
  private init() {
    this.translocoService = this._injector.get(TranslocoService);
    this.translocoService
      .selectTranslation("exceptions") // Specify the custom scope here
      .subscribe(lang => {
        Object.entries(lang).forEach(element => {
          let langKey = element[0];
          if (!langKey.startsWith("exceptions.")) {
            langKey = "exceptions." + langKey;
          }
          this.errorsList.push(langKey);
        });
      });
  }
}
