import { Component, Input, inject } from "@angular/core";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { ModalService } from "../../services/modal.service";
import { RouterLink } from "@angular/router";
import { TranslocoPipe, provideTranslocoScope } from "@jsverse/transloco";
import { ButtonModule } from "primeng/button";
import { SeverityType } from "../../enums/severity-type.enum";

@Component({
  selector: "app-notification-modal",
  templateUrl: "./notification-modal.component.html",
  standalone: true,
  imports: [RouterLink, TranslocoPipe, ButtonModule],
  providers: [provideTranslocoScope("exceptions")],
})
export class NotificationModalComponent {
  private readonly _modalService = inject(ModalService);
  severityType = SeverityType;
  @Input() notificationType!: NotificationType | string;
  @Input() toRoute!: string | null;
  @Input() modalImageUrl!: string;

  onClose() {
    this._modalService.close();
  }
}
