import { Injectable, inject } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { UserService } from "src/app/features/auth/services/user.service";
import { Observable, tap } from "rxjs";
import { ToastNotificationService } from "src/app/shared/services/toast-notification.service";
import { ToastNotificationType } from "src/app/shared/enums/toast-notification-type.enum";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private readonly _userService = inject(UserService);
  private readonly _toastNotificationService = inject(ToastNotificationService);
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this._toastNotificationService.showToast(
              ToastNotificationType.error,
              NotificationType.APP_USER_UNAUTHORIZED,
              5000
            );
            setTimeout(() => {
              this._userService.logout();
            }, 5000);
          }
        },
      })
    );
  }
}
