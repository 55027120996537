import { ValidationFieldError } from "./validation-field-error.model";

export class ValidationException {
  statusCode: number;
  statusText: string;
  error: ValidationFieldError[];

  constructor(
    statusCode: number,
    statusText: string,
    error: ValidationFieldError[]
  ) {
    this.statusCode = statusCode;
    this.statusText = statusText;
    this.error = error;
  }
}
