import { HttpClient } from '@angular/common/http';
import {
  Translation,
  TranslocoLoader,
} from '@jsverse/transloco';
import { Injectable, inject, } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly _http = inject(HttpClient);

  getTranslation(lang: string) {
    return this._http.get<Translation>(`/app/assets/i18n/${lang}.json`);
  }
}

