import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef, inject
} from "@angular/core";
import { LoadComponentService } from "../services/load-component.service";

@Directive({
  selector: "[appLoadComponent]",
  standalone: true,
})
export class LoadComponentDirective implements OnChanges {
  public viewContainerRef = inject(ViewContainerRef);
  private readonly _loadComponentService = inject(LoadComponentService);
  @Input() loadComponentId?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["loadComponentId"].firstChange) {
      this._loadComponentService.onLoadComponentDirectiveSubject.next(this);
    }
  }
}
