@if (isActive) {
  <div
    class="h-screen w-screen fixed left-0 bottom-0 z-10 bg-black bg-opacity-50"
    [@fadeInOut]>
    <div
      (click)="close()"
      class="h-screen w-screen fixed left-0 bottom-0 z-10 bg-black bg-opacity-50"></div>
    <div
      class="xxs:w-[90%] md:w-[70%] lg:w-3/5 xl:w-2/5 rounded-md max-h-[80vh] fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#FAFBFF] z-20">
      <div class="m-1 cursor-pointer absolute right-2 top-2" (click)="close()">
        <svg-icon
          src="/app/assets/icons/cancel.svg"
          svgClass="fill-slate-400 dark:fill-[#FFFFFF] w-6 h-6" />
      </div>
      @if (modal.data.title && modal.data.icon) {
        <div
          class="flex w-full gap-3 p-3 pl-3 md:pl-5 items-center bg-white rounded-md">
          <svg-icon
            src="app/assets/icons/{{ modal.data.icon }}.svg"
            svgClass="w-5 h-5 md:w-7 md:h-7 fill-[#E84538] dark:fill-[#000000]" />
          <h1 class="text-base md:text-lg font-bold">{{ modal.data.title }}</h1>
        </div>
      }
      <div class="overflow-y-auto max-h-[700px] p-1 m-1 md:p-1 md:m-1">
        <ng-template appLoadComponent />
      </div>
    </div>
  </div>
}
