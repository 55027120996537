import {
  Component,
  inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { Guid } from "guid-typescript";
import { ToastComponent } from "src/app/shared/components/toast/toast.component";
import { ToastItem } from "src/app/shared/models/toast-item.model";
import { ToastService } from "src/app/shared/services/toast.service";

@Component({
  selector: "app-chat-toats-list",
  templateUrl: "./chat-toats-list.component.html",
  standalone: true,
  imports: [ToastComponent],
})
export class ChatToatsListComponent implements OnInit {
  private readonly _toastService = inject(ToastService);
  @ViewChildren(ToastComponent) toastComponents!: QueryList<ToastComponent>;
  id: Guid = Guid.parse("670a9319-869d-49f8-b228-c701bcbe3b62");
  toasts: ToastItem<any>[] = [];

  ngOnInit(): void {
    this._toastService.$onOpen.subscribe((toast) => {
      if (Guid.createEmpty().equals(toast.id)) toast.id = Guid.create();
      this.toasts.push(toast);
      this.toastComponents.first?.create(toast);
    });
    this._toastService.$onClear.subscribe((toastId) => {
      this.toastComponents.first?.clear(toastId);
    });
  }
}
