import {
  ChangeDetectorRef,
  Component,
  inject,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ToastItem } from "../../models/toast-item.model";
import { Subscription } from "rxjs";
import { LoadComponentService } from "../../services/load-component.service";
import { Guid } from "guid-typescript";
import { Message, MessageService, SharedModule } from "primeng/api";
import { Toast, ToastModule } from "primeng/toast";
import { LoadComponentDirective } from "../../directives/load-component.directive";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  standalone: true,
  imports: [ToastModule, SharedModule, LoadComponentDirective],
})
export class ToastComponent {
  private readonly _loadComponentService = inject(LoadComponentService);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  private readonly _messageService = inject(MessageService);
  private readonly _toastService = inject(ToastService);
  toasts: ToastItem<any>[] = [];
  id!: Guid;
  @ViewChildren(Toast, { read: Toast }) toastRef!: QueryList<Toast>;
  loadComponentSubscription!: Subscription;

  constructor() {
    this._loadComponentService.$onLoadComponentDirective.subscribe(
      directive => {
        this.load(directive);
      }
    );
  }

  private load(directive: LoadComponentDirective) {
    const toastItem = this.toasts.find(
      x => x.id.toString() == directive.loadComponentId
    );
    if (!toastItem) return;
    const containerRef = directive.viewContainerRef;
    if (this.loadComponentSubscription) {
      this.loadComponentSubscription.unsubscribe();
    }
    this.loadComponentSubscription = this._loadComponentService
      .loadComponent(containerRef, async () => toastItem.component)
      .subscribe(component => {
        toastItem.onComponentInit(component);
      });
  }

  create(toast: ToastItem<any>) {
    this.toasts.push(toast);
    this._changeDetectorRef.detectChanges();
    const toastOptions: Message = {
      id: toast.id.toString(),
      key: "organik-toast",
      sticky: toast.toastData.sticky,
      life: toast.toastData.life,
      closable: toast.toastData.closable,
      severity: toast.toastData.severity,
    };
    this._messageService.add(toastOptions);
  }

  clear(id: Guid) {
    const index = this.toasts.findIndex(x => x.id.equals(id));
    if (index === -1) return;
    this.toastRef.first?.onMessageClose({
      message: { id: id.toString() },
      index: 0,
    });
  }

  onClose(e: any) {
    this._toastService.__removeToastId(e.message.id);
  }
}
