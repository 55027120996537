import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { Provider } from "@angular/core";
import { CapthcaInterceptorService } from "../core/interceptor/captcha.interceptor";
import { ErrorCatchingInterceptor } from "../core/interceptor/error.interceptor";
import { TokenInterceptorService } from "../core/interceptor/token.interceptor";
import { LoaderInterceptor } from "../core/interceptor/loader.interceptor";
import { UnauthorizedInterceptor } from "../core/interceptor/unauthorized.interceptor";

export function provideCore(): Provider[] {
  return [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CapthcaInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ];
}
