<div class="flex flex-col justify-between items-center gap-2">
  <img
    class="w-[20%]"
    src="/app/assets/images/{{ modalImageUrl }}"
    alt="Modal Image"
  />
  <h2 class="text-xl md:text-2xl text-secondary-2">
    {{ notificationType + ".title" | transloco }}
  </h2>
  <p class="p-2 text-center">{{ notificationType + ".message" | transloco }}</p>
  @if(toRoute){
  <div class="w-full p-2">
    <p-button
      type="button"
      [raised]="true"
      [label]="'modal.close' | transloco"
      [severity]="severityType.Secondary"
      (onClick)="onClose()"
      [routerLink]="toRoute"
    />
  </div>
  }
</div>
