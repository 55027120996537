import { Injectable, inject } from "@angular/core";
import { ModalService } from "./modal.service";
import { NotificationType } from "src/app/shared/enums/notification-type.enum";
import { ModalItem } from "../models/modal-item.model";
import { NotificationModalComponent } from "../components/notification-modal/notification-modal.component";
import { ModalData } from "../models/modal-data.model";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private readonly _modalService = inject(ModalService);

  openNotificationForSuccessHandling(
    notificationSuccessType: NotificationType,
    toRoute: string | null,
    modalImageUrl: string
  ) {
    this._modalService.open(
      new ModalItem<NotificationModalComponent>(
        NotificationModalComponent,
        component => {
          component.notificationType = notificationSuccessType;
          component.toRoute = toRoute;
          component.modalImageUrl = modalImageUrl;
        },
        new ModalData()
      )
    );
  }

  openNotificationForErrorHandling(
    notificationType: string,
    toRoute: string | null,
    modalImageUrl: string
  ) {
    this._modalService.open(
      new ModalItem<NotificationModalComponent>(
        NotificationModalComponent,
        component => {
          component.notificationType = notificationType;
          component.toRoute = toRoute;
          component.modalImageUrl = modalImageUrl;
        },
        new ModalData()
      )
    );
  }
}
