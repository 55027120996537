import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild, inject
} from "@angular/core";
import { LoadComponentDirective } from "../../directives/load-component.directive";
import { LoadComponentService } from "../../services/load-component.service";
import { Subscription } from "rxjs";
import { ModalService } from "../../services/modal.service";
import { ModalItem } from "../../models/modal-item.model";
import { SvgIconComponent } from "angular-svg-icon";

import { fadeInOut } from "../../animations/fade.animation";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  standalone: true,
  imports: [SvgIconComponent, LoadComponentDirective],
  animations: [fadeInOut],
})
export class ModalComponent implements OnInit, OnDestroy {
  private readonly _loadComponentService = inject(LoadComponentService);
  private readonly _modalService = inject(ModalService);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  @ViewChild(LoadComponentDirective, { static: false })
  loadComponentDirective!: LoadComponentDirective;
  loadComponentSubscription!: Subscription;
  openSubscription!: Subscription;
  closeSubscription!: Subscription;
  isActive = false;
  modal!: ModalItem<any>;

  ngOnInit(): void {
    this.openSubscription = this._modalService.$onOpen.subscribe((modal) =>
      this.open(modal)
    );

    this.closeSubscription = this._modalService.$onClose.subscribe(() =>
      this.close()
    );
  }

  ngOnDestroy(): void {
    this.loadComponentSubscription.unsubscribe();
    this.openSubscription.unsubscribe();
    this.closeSubscription.unsubscribe();
  }

  load() {
    const containerRef = this.loadComponentDirective.viewContainerRef;
    if (this.loadComponentSubscription) {
      this.loadComponentSubscription.unsubscribe();
    }
    this.loadComponentSubscription = this._loadComponentService
      .loadComponent(containerRef, async () => this.modal.component)
      .subscribe((component) => {
        this.modal.onComponentInit(component);
      });
  }

  open(modal: ModalItem<any>) {
    this.isActive = true;
    this.modal = modal;
    this._changeDetectorRef.detectChanges();
    this.load();
  }

  close() {
    this.isActive = false;
    this._changeDetectorRef.detectChanges();
  }
}
