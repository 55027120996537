import { Injectable, inject } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable, from, lastValueFrom } from "rxjs";
import { CaptchaService } from "src/app/shared/modules/captcha/service/captcha.service";

@Injectable()
export class CapthcaInterceptorService implements HttpInterceptor {
  private readonly _captchaService = inject(CaptchaService);

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(req, next));
  }

  async handle(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    if (req.method == "GET") {
      return await lastValueFrom(next.handle(req));
    }
    const token = await lastValueFrom(this._captchaService.verify());
    if (token == "") return await lastValueFrom(next.handle(req));
    return await lastValueFrom(
      next.handle(
        req.clone({
          setHeaders: {
            "X-Captcha-Token": token,
          },
        })
      )
    );
  }
}
