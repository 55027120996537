import { Injectable, Type, ViewContainerRef } from "@angular/core";
import { Observable, Subject, from } from "rxjs";
import { LoadComponentDirective } from "../directives/load-component.directive";

@Injectable({
  providedIn: "root",
})
export class LoadComponentService {
  onLoadComponentDirectiveSubject = new Subject<LoadComponentDirective>();
  $onLoadComponentDirective =
    this.onLoadComponentDirectiveSubject.asObservable();

  // Dinamik olarak bir bileşen yükler ve bu bileşenin referansını Observable içinde döndürür.
  loadComponent<T>(
    viewContainerRef: ViewContainerRef,
    componentResolver: () => Promise<Type<any>>
  ): Observable<T> {
    return from(
      (async () => {
        // componentResolver fonksiyonu, dinamik olarak yüklenmek istenen bileşenin referansını Promise olarak döndürür.
        const dynamicComponent = await componentResolver();

        // İlgili ViewContainerRef içindeki mevcut içeriği temizler.
        viewContainerRef.clear();
        // Dinamik bileşeni oluşturur ve bu bileşenin referansını saklar.
        const componentRef = viewContainerRef.createComponent(dynamicComponent);
        // Oluşturulan bileşenin tipini dönüştürerek bileşen referansını Observable içinde döndürür.
        return componentRef.instance as T;
      })()
    );
  }
}
