import { Routes } from "@angular/router";

export const APP_ROUTES: Routes = [
  {
    path : "",
    loadChildren: () => import("./auth.routes").then(m => m.AUTH_ROUTES),
  },  
];



